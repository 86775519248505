import { MDXProvider } from '@mdx-js/react';
import { withLayout } from '../components/layout';

function Layout({ children }) {
  return (
    <div>
      <MDXProvider>{children}</MDXProvider>
    </div>
  );
}

export default withLayout(Layout);
